import React from 'react';
import './projects.css';
import ProjectCard from './project-card.js';
import Fade from 'react-reveal/Slide';

import ShopProject from '../../images/projects/shopfront.png';
import KeepMeAlive from '../../images/projects/keepmealive.png';
import PubQuizBuddy from '../../images/projects/pubquizbuddy.png';
import GymDuck from '../../images/projects/gymduck.png';
import Pokedex from '../../images/projects/pokedex.png';
import CatCharity from '../../images/projects/catcharity.png';

const Projects = () => {

    const projects = [
        {
            "projectName": "E-commerce site (ongoing)",
            "projectPhoto": ShopProject,
            "languages": ["React", "Python/Django"],
            "projectUrl": "https://github.com/Wright0/online_shop_fe",
            "codeBase": "https://github.com/Wright0/online_shop_fe"
        },
        {
            "projectName": "Keep Me Alive - Plant Game",
            "projectPhoto": KeepMeAlive,
            "languages": ["React", "Java/Spring"],
            "projectUrl": "https://keep-me-alive-plant.herokuapp.com/",
            "codeBase":"https://github.com/Wright0/keepMeAlive-PlantApp"
        },
        {
            "projectName": "Pub Quiz Buddy",
            "projectPhoto": PubQuizBuddy,
            "languages": ["Vue.js", "Express.js", "MongoDB"],
            "projectUrl": "https://pubquizbuddy.herokuapp.com/",
            "codeBase":"https://github.com/Wright0/pub-quiz-buddy"
        },
        {
            "projectName": "Gym Duck",
            "projectPhoto": GymDuck,
            "languages": ["Ruby/Sinatra", "PostgreSQL"],
            "projectUrl": "https://gym-duck.herokuapp.com/",
            "codeBase":"https://github.com/Wright0/gymduck"
        },
        {
            "projectName": "Pokedex",
            "projectPhoto": Pokedex,
            "languages": ["HTML/CSS", "React"],
            "projectUrl": null,
            "codeBase":"https://github.com/Wright0/react-pokedex"
        },
        {
            "projectName": "Cat Charity site",
            "projectPhoto": CatCharity,
            "languages": ["HTML/CSS"],
            "projectUrl": null,
            "codeBase":"https://github.com/Wright0/cat-charity/tree/master"
        }
    ]

    const projectCards = () => projects.map(project => {
        return (
            <ProjectCard 
                key={project.projectName}
                projectPhoto={project.projectPhoto}
                projectName={project.projectName}
                languages={project.languages}
                projectUrl={project.projectUrl}
                codeBase={project.codeBase}
            />
        )
    })

    return(
            <article id="projects" className="projects">
            <h2>Projects</h2>
            <Fade cascade right duration={1500}>
            <section>
                {projectCards()}
            </section>
            </Fade>

            </article>
    )
}

export default Projects;