import React from 'react';

import GitHubLogo from '../images/logos/github_logo.svg';
import TwitterLogo from '../images/logos/twitter_logo.svg';
import LinkedInLogo from '../images/logos/linkedin_logo.svg';

import './contact-me.css';

const ContactMe = () => {
    return (
            <section id="contact" className="contact-page">
                <h2>Get in touch!</h2>
            <div>
                <a target="blank" href="https://github.com/Wright0"><img id="github-icon" src={GitHubLogo} alt="Github logo"/></a>
                <a target="blank" href="https://twitter.com/WrightMOlivia"><img id="twitter-icon" src={TwitterLogo} alt="Twitter logo"/></a>
                <a target="blank" href="https://www.linkedin.com/in/omwright/"><img id="linkedin-icon" src={LinkedInLogo} alt="LinkedIn Logo"/></a>
            </div>
            <p>	hello@oliviawright.dev</p>
        </section>
    )
}

export default ContactMe;
