import React from "react";

import Layout from "../components/layout-header-footer/layout.js";
import SEO from "../components/seo.js";
import AboutMe from '../components/about-me.js';
import LanguagesTools from '../components/languages-tools.js'
import Projects from '../components/project-components/projects.js'
import ContactMe from '../components/contact-me.js';

import "../components/global.css"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <AboutMe/>
    <LanguagesTools/>
    <Projects/>
    <ContactMe/>
  </Layout>
)

export default IndexPage;
