import React from 'react';
import Fade from 'react-reveal/Slide';
import { Link } from 'gatsby';

import JavaScriptLogo from '../images/logos/languages-tools/js_logo.svg';
import ReactLogo from '../images/logos/languages-tools/react_logo.svg';
import VueLogo from '../images/logos/languages-tools/vue_logo.svg';
import CSSLogo from '../images/logos/languages-tools/CSS3_logo.svg';
import HTMLLogo from '../images/logos/languages-tools/html5_logo.svg';
import NodeLogo from '../images/logos/languages-tools/nodejs_logo.svg';
import RubyLogo from '../images/logos/languages-tools/ruby_logo.svg';
import JavaLogo from '../images/logos/languages-tools/java_logo.svg';
import SpringLogo from '../images/logos/languages-tools/spring_logo.svg';
import PythonLogo from '../images/logos/languages-tools/python_logo.svg';
import DjangoLogo from '../images/logos/languages-tools/django_logo.svg';
import PSQLLogo from '../images/logos/languages-tools/psql_logo.svg';
import MongoLogo from '../images/logos/languages-tools/mongodb_logo.svg';
import GitLogo from '../images/logos/languages-tools/git_icon.svg';
import Photoshop from '../images/logos/languages-tools/photoshop_logo.svg';

import './language-tools.css'

const LanguagesTools = () => {

    return (
        <article className="tools">
            <span>
                <h2>Languages and tools</h2>
                <p>I have worked on both the frontend and backend of projects, but in my most recent role the big focus was on backend. Our Django application was server-side rendered, and utilised Django's templating system for the frontend. For personal projects, I enjoy working with React, though I am keen to explore Vue in more depth.</p>
                <p>I have some experience with non-relational databases (MongoDB), but I am a big SQL fan, particularly of PostgreSQL.</p>
                <p>As a hobby, I also enjoy making digital art in Photoshop. I began doing this in my teen years, creating banners, icons and designs for online forums. From that, I learned to create photo manipulations and edit photographs. <Link to="/2020-06-21/my-red-carpet-rebellion/">I talk about this here</Link>.</p>
            </span>
            
            <section className="tool-boxes">
                
                <div>
                    <h3>Front-end</h3>
                    <span><img src={JavaScriptLogo} alt="JavaScript logo"/> <p>JavaScript</p></span>
                    <span><img src={ReactLogo} alt="React logo"/> <p>React</p></span>
                    <span><img src={VueLogo} alt="Vue logo"/><p>Vue.js</p></span>
                    <span><img src={CSSLogo} alt="CSS logo"/> <p>CSS</p></span>
                    <span><img src={HTMLLogo} alt="HTML logo"/><p>HTML</p></span>
                </div>

                <div>
                    <h3>Back-end</h3>
                    <span><img src={PythonLogo} alt="Python logo"/><p>Python</p></span>
                    <span><img src={DjangoLogo} alt="Django logo"/><p>Django</p></span> 
                    <span><img src={NodeLogo} alt="Node logo"/><p>Node.js</p></span>
                    <span><img src={RubyLogo} alt="Ruby logo"/><p>Ruby</p></span>                    
                </div>

                <div>
                    <h3>Other tools</h3>
                    <span><img src={PSQLLogo} alt="PostgreSQL logo"/><p>PostgreSQL</p></span>
                    <span><img src={MongoLogo} alt="MongoDB logo"/><p>MongoDB</p></span>
                    <span><img src={GitLogo} alt="git logo"/><p>Git</p></span>
                    <span><img src={Photoshop} alt="Photoshop logo"/><p>Photoshop</p></span>
                </div>
            </section>
        </article>
    )
}

export default LanguagesTools;

            